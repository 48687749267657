// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-agb-jsx": () => import("./../../../src/pages/agb.jsx" /* webpackChunkName: "component---src-pages-agb-jsx" */),
  "component---src-pages-app-index-tsx": () => import("./../../../src/pages/app/index.tsx" /* webpackChunkName: "component---src-pages-app-index-tsx" */),
  "component---src-pages-app-rechtliches-agb-tsx": () => import("./../../../src/pages/app/rechtliches/agb.tsx" /* webpackChunkName: "component---src-pages-app-rechtliches-agb-tsx" */),
  "component---src-pages-app-rechtliches-datenschutz-tsx": () => import("./../../../src/pages/app/rechtliches/datenschutz.tsx" /* webpackChunkName: "component---src-pages-app-rechtliches-datenschutz-tsx" */),
  "component---src-pages-app-rechtliches-haftungsausschluss-tsx": () => import("./../../../src/pages/app/rechtliches/haftungsausschluss.tsx" /* webpackChunkName: "component---src-pages-app-rechtliches-haftungsausschluss-tsx" */),
  "component---src-pages-app-rechtliches-impressum-tsx": () => import("./../../../src/pages/app/rechtliches/impressum.tsx" /* webpackChunkName: "component---src-pages-app-rechtliches-impressum-tsx" */),
  "component---src-pages-app-rechtliches-index-tsx": () => import("./../../../src/pages/app/rechtliches/index.tsx" /* webpackChunkName: "component---src-pages-app-rechtliches-index-tsx" */),
  "component---src-pages-app-rechtliches-user-delete-tsx": () => import("./../../../src/pages/app/rechtliches/user-delete.tsx" /* webpackChunkName: "component---src-pages-app-rechtliches-user-delete-tsx" */),
  "component---src-pages-blog-blog-slug-slug-jsx": () => import("./../../../src/pages/blog/{Blog.slug_slug}.jsx" /* webpackChunkName: "component---src-pages-blog-blog-slug-slug-jsx" */),
  "component---src-pages-blog-index-jsx": () => import("./../../../src/pages/blog/index.jsx" /* webpackChunkName: "component---src-pages-blog-index-jsx" */),
  "component---src-pages-datenschutz-jsx": () => import("./../../../src/pages/datenschutz.jsx" /* webpackChunkName: "component---src-pages-datenschutz-jsx" */),
  "component---src-pages-email-bestaetigt-jsx": () => import("./../../../src/pages/email-bestaetigt.jsx" /* webpackChunkName: "component---src-pages-email-bestaetigt-jsx" */),
  "component---src-pages-email-bestaetigung-jsx": () => import("./../../../src/pages/email-bestaetigung.jsx" /* webpackChunkName: "component---src-pages-email-bestaetigung-jsx" */),
  "component---src-pages-entspannungszeit-tsx": () => import("./../../../src/pages/entspannungszeit.tsx" /* webpackChunkName: "component---src-pages-entspannungszeit-tsx" */),
  "component---src-pages-faq-jsx": () => import("./../../../src/pages/faq.jsx" /* webpackChunkName: "component---src-pages-faq-jsx" */),
  "component---src-pages-gruppe-jsx": () => import("./../../../src/pages/gruppe.jsx" /* webpackChunkName: "component---src-pages-gruppe-jsx" */),
  "component---src-pages-haftungsausschluss-jsx": () => import("./../../../src/pages/haftungsausschluss.jsx" /* webpackChunkName: "component---src-pages-haftungsausschluss-jsx" */),
  "component---src-pages-herausforderungen-bluthochdruck-jsx": () => import("./../../../src/pages/herausforderungen/bluthochdruck.jsx" /* webpackChunkName: "component---src-pages-herausforderungen-bluthochdruck-jsx" */),
  "component---src-pages-herausforderungen-gedankenkreise-jsx": () => import("./../../../src/pages/herausforderungen/gedankenkreise.jsx" /* webpackChunkName: "component---src-pages-herausforderungen-gedankenkreise-jsx" */),
  "component---src-pages-herausforderungen-index-jsx": () => import("./../../../src/pages/herausforderungen/index.jsx" /* webpackChunkName: "component---src-pages-herausforderungen-index-jsx" */),
  "component---src-pages-herausforderungen-schlafkrankheit-jsx": () => import("./../../../src/pages/herausforderungen/schlafkrankheit.jsx" /* webpackChunkName: "component---src-pages-herausforderungen-schlafkrankheit-jsx" */),
  "component---src-pages-herausforderungen-zaehneknirschen-jsx": () => import("./../../../src/pages/herausforderungen/zaehneknirschen.jsx" /* webpackChunkName: "component---src-pages-herausforderungen-zaehneknirschen-jsx" */),
  "component---src-pages-impressum-jsx": () => import("./../../../src/pages/impressum.jsx" /* webpackChunkName: "component---src-pages-impressum-jsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-instagram-jsx": () => import("./../../../src/pages/instagram.jsx" /* webpackChunkName: "component---src-pages-instagram-jsx" */),
  "component---src-pages-kinetic-plus-jsx": () => import("./../../../src/pages/kinetic-plus.jsx" /* webpackChunkName: "component---src-pages-kinetic-plus-jsx" */),
  "component---src-pages-kontakt-jsx": () => import("./../../../src/pages/kontakt.jsx" /* webpackChunkName: "component---src-pages-kontakt-jsx" */),
  "component---src-pages-kurse-autogenes-training-onlinekurs-jsx": () => import("./../../../src/pages/kurse/autogenes-training-onlinekurs.jsx" /* webpackChunkName: "component---src-pages-kurse-autogenes-training-onlinekurs-jsx" */),
  "component---src-pages-kurse-index-jsx": () => import("./../../../src/pages/kurse/index.jsx" /* webpackChunkName: "component---src-pages-kurse-index-jsx" */),
  "component---src-pages-kurse-krankenkasse-jsx": () => import("./../../../src/pages/kurse/krankenkasse.jsx" /* webpackChunkName: "component---src-pages-kurse-krankenkasse-jsx" */),
  "component---src-pages-kurse-kursquiz-jsx": () => import("./../../../src/pages/kurse/kursquiz.jsx" /* webpackChunkName: "component---src-pages-kurse-kursquiz-jsx" */),
  "component---src-pages-kurse-progressive-muskelentspannung-onlinekurs-jsx": () => import("./../../../src/pages/kurse/progressive-muskelentspannung-onlinekurs.jsx" /* webpackChunkName: "component---src-pages-kurse-progressive-muskelentspannung-onlinekurs-jsx" */),
  "component---src-pages-kurse-stressmanagement-onlinekurs-jsx": () => import("./../../../src/pages/kurse/stressmanagement-onlinekurs.jsx" /* webpackChunkName: "component---src-pages-kurse-stressmanagement-onlinekurs-jsx" */),
  "component---src-pages-mentoring-tsx": () => import("./../../../src/pages/mentoring.tsx" /* webpackChunkName: "component---src-pages-mentoring-tsx" */),
  "component---src-pages-nachricht-gesendet-jsx": () => import("./../../../src/pages/nachricht-gesendet.jsx" /* webpackChunkName: "component---src-pages-nachricht-gesendet-jsx" */),
  "component---src-pages-newsletter-tsx": () => import("./../../../src/pages/newsletter.tsx" /* webpackChunkName: "component---src-pages-newsletter-tsx" */),
  "component---src-pages-partner-jsx": () => import("./../../../src/pages/partner.jsx" /* webpackChunkName: "component---src-pages-partner-jsx" */),
  "component---src-pages-podcast-folgen-jsx": () => import("./../../../src/pages/podcast/folgen.jsx" /* webpackChunkName: "component---src-pages-podcast-folgen-jsx" */),
  "component---src-pages-podcast-folgen-letzte-folge-jsx": () => import("./../../../src/pages/podcast/folgen/letzte-folge.jsx" /* webpackChunkName: "component---src-pages-podcast-folgen-letzte-folge-jsx" */),
  "component---src-pages-podcast-index-jsx": () => import("./../../../src/pages/podcast/index.jsx" /* webpackChunkName: "component---src-pages-podcast-index-jsx" */),
  "component---src-pages-termin-jsx": () => import("./../../../src/pages/termin.jsx" /* webpackChunkName: "component---src-pages-termin-jsx" */),
  "component---src-pages-ueber-uns-jsx": () => import("./../../../src/pages/ueber-uns.jsx" /* webpackChunkName: "component---src-pages-ueber-uns-jsx" */),
  "component---src-pages-weg-zur-meditation-tsx": () => import("./../../../src/pages/weg-zur-meditation.tsx" /* webpackChunkName: "component---src-pages-weg-zur-meditation-tsx" */),
  "component---src-pages-wissen-jsx": () => import("./../../../src/pages/wissen.jsx" /* webpackChunkName: "component---src-pages-wissen-jsx" */),
  "component---src-templates-episodes-jsx": () => import("./../../../src/templates/episodes.jsx" /* webpackChunkName: "component---src-templates-episodes-jsx" */),
  "component---src-templates-topics-jsx": () => import("./../../../src/templates/topics.jsx" /* webpackChunkName: "component---src-templates-topics-jsx" */)
}

